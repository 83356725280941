.mentor_card_container {
  margin-top: 3rem;
  max-width: 400px;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imagesHolder {
  width: 80px;
  height: 80px;
  overflow: hidden;
}

.mentorimg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.mentor_name {
  margin-top: 0.5rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  font-size: 1.1rem;
}

.comingsoon {
  background-color: #f78c40;
  color: white;
  padding: 0.5rem 1.25rem;
  font-size: 0.9rem;
  border: none;
  border-radius: 5px;
}

.box {
  display: flex;
  justify-content: space-between;
}

.mentor_interest {
  color: gray;
  font-size: 0.9rem;
  text-align: right;
  font-weight: 600;
  width: 150px;
}

.mentor_source {
  color: gray;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 600;
  margin-bottom: 0.75rem;
  width: 150px;
}

.mentor_designation {
  width: 90%;
  margin-bottom: 0.75rem;
  margin-top: 0.4rem;
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
}

.linkedinimg {
  height: 1.75rem;
  width: 1.75rem;
  margin-top: 0.5rem;
}

.textdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}