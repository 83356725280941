.fv_image {
  max-width: 20rem;
}

* {
  margin: 0;
  padding: 0;
}

.first_view_container {
  margin: 2rem;
}

.first_view {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.fv_img {
  display: block;
  width: 20rem;
}

.fv_heading {
  font-size: 2.8rem;
  font-weight: 700;
  color: #404040;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: 0.5rem;
  text-align: center;
  max-width: 40rem;
}

.fv_heading span {
  color: #f6842c;
}

.atag {
  display: initial;
  vertical-align: initial;
  white-space: initial;
  max-width: 100%;
}

.fv_content {
  margin: 0.5rem auto 0;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  text-align: center;
  max-width: 40rem;
}

.fv_content_karma {
  color: #f6842c;
  font-weight: 500;
  font-size: 1.1rem;
}

.register_now {
  background-color: #f6842c;

  padding: 0.5rem 1.5rem;
  color: white;
  border-radius: 8px;

  display: block;
  margin: auto;
  margin-top: 1rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    display: block;
    margin: 6rem;
  }

  .fv_image {
    max-width: 30rem;
    margin-left: 2rem;
  }

  .fv_heading {
    font-size: 4.25rem;
    text-align: left;
  }

  .fv_content {
    font-size: 1.1rem;
    text-align: left;
  }
}

@media only screen and (min-width: 1300px) {
  .register_now {
    margin-left: 0;
  }
}

@media only screen and (max-width: 1350px) {
  .fv_heading {
    text-align: center;
  }

  .fv_content {
    text-align: center;
  }
}

.second_view_container {
  margin: 2rem;
  margin-top: 4rem;
}

.sv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.75rem;
  font-weight: 700;
  line-height: 2.75rem;
  color: #404040;
  text-align: center;
}

.sv_heading span {
  color: #f6842c;
}

.sv_content {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1rem;
  max-width: 40rem;
  text-align: center;
  margin: 0 auto;
}

.sv_content span {
  color: #f6842c;
}

.step_description a {
  color: #f6842c;
  text-decoration: none;

  display: initial;
  vertical-align: initial;
  white-space: initial;
  max-width: 100%;
}

.sv_content span a:hover {
  text-decoration: underline;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    display: block;
    margin: 6rem;
  }

  .sv_heading {
    font-size: 2.25rem;
    text-align: center;
  }

  .sv_content {
    font-size: 1.1rem;
    text-align: center;
  }
}

.steps_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  text-align: center;
}

.step {
  margin: 1rem;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  border-radius: 10px;
  min-width: 20rem;
  max-width: 30rem;
}

.step_heading {
  font-family: "Noto Sans";
  font-size: 1.3rem;
  font-weight: 600;
  color: #f6842c;
}

.step_content {
  font-family: "Poppins";
  font-size: 1.1rem;
  line-height: 1.75rem;
}

.step_description {
  font-family: "Poppins";
  font-size: 1rem;
  margin-top: 1rem;
}

/* Steps Styling */

.steps {
  margin-top: 1rem;
}

.steps_ulist_sub li {
  margin-top: 0.5rem;
}

b {
  color: #404040;
}

.steps_ulist li {
  margin-top: 1rem;
}

.sample_container {
  margin: 2rem auto 0;
  background: #f5f5f5;
  padding: 2rem;
  border-radius: 0.2rem;
  max-width: 50rem;
}

.callout {
  margin: 2rem auto 0;
  border: 1px solid #f78c4080;
  padding: 2rem;
  border-radius: 0.2rem;
  max-width: 50rem;
}

.sample_header {
  font-size: 1.1rem;
  font-weight: 500;
  color: #404040;
}

.sample_message {
  margin: 0.5rem 0;
}

.sample_img {
  margin-top: 1rem;
  width: 20rem;
  border-radius: 5px;
}

@media only screen and (min-width: 1000px) {
  .sample_container,
  .callout {
    margin: 4rem auto 0;
  }

  .steps_ulist_sub li {
    width: 45%;
  }

  .sample_message {
    margin-top: 0;
    font-size: 1.05rem;
  }

  .sample_img {
    margin-top: 1rem;
    width: 15rem;
    border-radius: 5px;
  }
}
