* {
  padding: 0;
  margin: 0;
}

.first_view_container {
  padding: 0 2rem;
  background-color: #ffffff;
}

.fv_img {
  width: 15rem;
  display: block;
  margin: auto;
  margin-top: 2rem;
}

.fv_texts {
  text-align: center;
}

.fv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2.5rem;
  font-weight: 600;
  color: #303030;
}

.fv_heading span {
  font-weight: 600;
  color: #f78c40;
}

.fv_tagline {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
}

@media only screen and (min-width: 768px) {
  .first_view {
    max-width: 1300px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .fv_img {
    display: block;
    margin: auto;
    width: 30rem;
  }

  .fv_texts {
    text-align: left;
    width: 50%;
  }

  .fv_heading {
    font-family: "Noto Sans", sans-serif;
    font-size: 4.55rem;
    line-height: 5rem;
    font-weight: 600;
  }

  .fv_tagline {
    margin-top: 1rem;
    font-size: 1.1rem;
    width: 90%;
  }
}

/* Second View Styles */

.second_view_container {
  margin-top: 3rem;
  padding: 2rem;
  background-color: #ffffff;
}

.course {
  display: grid;
  row-gap: 1rem;
  column-gap: 1rem;
  justify-items: center;
}

@media only screen and (min-width: 768px) {
  .course {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media only screen and (min-width: 992px) {
  .second_view_container {
    padding: 2rem;
  }

  .second_view {
    max-width: 1300px;
    margin: auto;
  }

  .course {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media only screen and (min-width: 1300px) {
  .second_view_container {
    padding: 0rem;
  }

  .course {
    grid-template-columns: 25% 25% 25% 25%;
  }
}
