.mainContainer {
    padding:10px;
    display: flex;
    flex-direction: column; 
    flex-wrap: wrap;
    max-width: 350px;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.082);
    border-radius: 15px;
    font-size: 13.5px;
}

.subContainer{
    display:flex ;
    justify-content: flex-end;
    margin-top: 1;
    height: min-content;
    align-items: center;
    padding-bottom: 0;
    line-height: 0;
}