.sample_container {
  margin: 2rem auto 0;
  background: #f5f5f5;
  padding: 2rem;
  border-radius: 0.2rem;
  /* max-width: 50rem; */
}

.sample_header {
  font-size: 1.1rem;
  font-weight: 500;
  color: #404040;
}

.sample_message {
  margin: 0.5rem 0;
}

.sample_message a {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
}

.sample_img {
  margin-top: 1rem;
  border-radius: 5px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .sample_container {
    max-width: 50vh;
    width: 100%;
  }
}

@media only screen and (min-width: 1000px) {
  .sample_container {
    max-width: 50rem;
    margin-top: 4rem;
  }

  .sample_message {
    margin-top: 0;
    font-size: 1.05rem;
  }

  .sample_img {
    margin-top: 1rem;
    border-radius: 5px;
  }
}
