* {
  margin: 0;
  padding: 0;
}

.first_view_container {
  margin: 2rem;
}

.first_view {
  display: flex;
  flex-direction: column-reverse;
}

.fv_heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #404040;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: 0.5rem;
}

.fv_heading span,
.orange {
  color: #f6822a;
}

.fv_content {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.fv_img {
  width: 25rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    margin: 0 6rem;
  }

  .fv_texts {
    width: 45vw;
  }

  .fv_heading {
    font-size: 5rem;
  }

  .fv_content,
  .aquote {
    width: 80%;
  }

  .fv_content {
    font-size: 1.1rem;
  }

  .fv_texts {
    padding-right: 3vw;
    color: #404040;
  }

  .fv_image {
    display: block;
  }

  .fv_img {
    width: 35rem;
  }

  .first_view {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }
}

.first_view_container {
  margin: 2rem;
}

.register {
  background-color: #f6822a;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  color: white;
  margin-top: 1rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    display: block;
  }
}

@media only screen and (max-width: 1000px) {
  .fv_img {
    display: block;
    margin: auto;
  }

  .fv_heading {
    text-align: center;
    font-size: 3.5rem;
  }

  .fv_content {
    text-align: center;
  }

  .register {
    display: block;
    margin: auto;
    margin-top: 1rem;
  }
}

/* Second View Styles */

.second_view_container {
  margin: 2rem;
}

.sv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.75rem;
  color: #f78c40;
}

.sv_content {
  margin-top: 0.25rem;
  font-size: 0.9rem;
  font-family: "Poppins";
}

.bootcamps {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 2rem;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    margin: 3rem 3rem;
  }

  .sv_heading {
    font-size: 2.25rem;
    line-height: 4rem;
    width: 60vw;
  }

  .sv_content {
    width: 30%;
    margin-top: 0;
    font-size: 1.05rem;
  }
}

.bootcamp {
  max-width: 22rem;
  box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -webkit-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 1px 1px 45px -5px rgba(0, 0, 0, 0.08);
  padding: 30px 25px;
  border-radius: 10px;
}

.b_heading {
  font-size: 1.25rem;
  font-family: "Noto Sans";
  color: #f6822a;
}

.b_tagline {
  font-family: "Poppins";
  color: #404040;
  font-size: 0.9rem;
}

.b_button {
  background-color: #f6822a;
  color: #fff;
  margin-top: 1rem;
  font-size: 0.9rem;
  font-family: "Noto Sans";
  border-radius: 5px;
  padding: 0.35rem 1.5rem;
}

@media only screen and (min-width: 1300px) {
  .second_view_container {
    margin: 3rem 6rem;
  }
}
