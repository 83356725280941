.first_view_container {
  margin: 2rem;
}

.supporters {
  color: #404040;
  margin-top: 1rem;
  margin-bottom: 2vh;
  display: flex;
  align-items: center;
  /* justify-content: center; */
}

.supporters span {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.1rem;
  display: block;
  width: 9rem;
  font-weight: 600;
  margin-right: 1rem;
}

.supporter {
  width: 4rem;
  margin-right: 1rem;
  border-radius: 50px;
  box-shadow: 0 1px 12px 0 rgba(193, 195, 211, 0.37);
}

.svs_box,
.requirements ul {
  list-style: disc;
}

.first_view {
  display: flex;
  flex-direction: column-reverse;
}

.fv_heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #404040;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: 0.5rem;
}

.fv_heading span,
.orange {
  color: #00bc83;
}

.fv_content {
  font-family: "Poppins", sans-serif;
  font-size: 0.95rem;
}

.fv_img {
  width: 25rem;
}

.register {
  user-select: none;
  background-color: #b1b1b1;
  padding: 0.5rem 1.5rem;
  border-radius: 5px;
  color: white;
  margin-top: 1rem;
}
.officehrs {
  margin-top: 1rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  color: #404040;
}

.officehrs span {
  color: #00bc83;
}
.fv_subheading {
  color: #00bc83;
  font-family: "Poppins", sans-serif;
  margin-top: -1.5rem;
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.startingdate {
  margin-top: 1rem;
  font-family: "Noto Sans";
  font-size: 1.1rem;
  font-weight: 500;
}

.startingdate span {
  color: #00bc83;
}

@media only screen and (max-width: 1000px) {
  .fv_img {
    display: block;
    margin: auto;
  }

  .startingdate {
    text-align: center;
  }

  .fv_subheading {
    text-align: center;
  }

  .fv_heading {
    text-align: center;
    font-size: 3.5rem;
  }

  .fv_content {
    text-align: center;
  }

  .atag {
    display: initial;
    vertical-align: initial;
    white-space: initial;
    max-width: 100%;
  }

  .register {
    display: block;
    margin: auto;
    margin-top: 1rem;
  }

  .supporters {
    justify-content: center;
  }
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    margin: 0 6rem;
  }

  .fv_texts {
    width: 45vw;
  }

  .fv_heading {
    font-size: 5rem;
  }

  .fv_content {
    width: 80%;

    font-size: 1.1rem;
  }

  .fv_texts {
    padding-right: 3vw;
    color: #404040;
  }

  .fv_image {
    display: block;
  }

  .fv_img {
    width: 35rem;
  }

  .first_view {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }
}

/* //Second View Styles */

.second_view_container {
  margin: 2rem;
}

.second_view {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    margin: 0 6rem;
    margin-top: 2rem;
  }
}

.sv_heading {
  font-size: 2rem;
  font-family: "Noto Sans", sans-serif;
  font-weight: 500;
  color: #404040;
}

.sv_content {
  font-family: "Poppins";
  font-size: 1rem;
}

.sv_heading span {
  color: #00bc83;
}

@media only screen and (min-width: 1000px) {
  .sv_heading {
    font-size: 2rem;
  }

  .sv_content {
    width: 30%;
  }
}

.sv_sections {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.sv_section_one {
  margin-top: 2rem;
  width: 80rem;
}

.section_preheading {
  font-family: "Poppins";
  color: #00bc83;
  margin-bottom: -0.75rem;
}

.section_heading {
  font-size: 2rem;
  font-weight: 300;
  color: #404040;
  font-family: "Noto Sans";
}

.svs_boxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
  margin-top: 1rem;
}

.svs_box {
  padding: 25px 25px;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 1px 12px 0 rgb(193 195 211 / 37%);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
  width: 21rem;
}

.svs_box ul {
  margin-left: 2rem;
  font-family: "Poppins";
}

.box_header {
  font-family: "Noto Sans", sans-serif;
  font-size: 1.2rem;
  color: #00bc83;
  font-weight: 400;
}

.sv_section_second {
  margin-top: 2rem;
  width: 20rem;
}

@media only screen and (max-width: 1000px) {
  .sv_texts {
    text-align: center;
  }

  .sv_section_second {
    margin-top: 2rem;
    width: 100%;
  }

  .sv_section_one {
    margin-top: 2rem;
    width: 100%;
  }

  .svs_box {
    display: block;
    margin: auto;
    width: 100%;
  }
}

/* Third View Styles */
.third_view_container {
  margin: 2rem;
}

.third_view {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .third_view_container {
    margin: 0 6rem;
    margin-top: 2rem;
  }
}

.detailed_structure {
  padding: 15px 15px;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 1px 12px 0 rgb(193 195 211 / 37%);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
  margin-top: 1rem;
  max-width: 22rem;
}

.plan_header {
  margin-top: 2.25rem;
  font-size: 1.75rem;
  font-weight: 500;
  font-family: "Noto Sans", sans-serif;
}

.plan_header span {
  color: #404040;
  font-weight: 600;
}

.plan_day {
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

.plan_title {
  font-family: "Poppins";
  font-size: 1.2rem;
  color: #00bc83;
}

.topic {
  margin: 1rem;
  color: #404040;
}

.topic_number {
  font-family: "Poppins";
  color: #00bc83;
  font-size: 1.2rem;
}

.topic_title {
  font-size: 1.1rem;
  font-family: "Poppins";
  font-weight: 500;
}

.topic_subtitle {
  font-family: "Noto Sans";
  font-size: 0.95rem;
}

.tv_plan_structure {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 1.6rem;
}

@media only screen and (max-width: 1000px) {
  .detailed_structure {
    max-width: 30rem;
  }
}

/* Fourth View Styles */

.fourth_view_container {
  margin: 2rem;
}

.fourth_view {
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 1000px) {
  .fourth_view_container {
    margin: 0 6rem;
    margin-top: 2rem;
  }
}

.requirements ul {
  margin-top: 1rem;
  margin-left: 1rem;
}

.requirements ul li {
  margin-top: 0.25rem;
  font-family: "Poppins";
  color: #404040;
}

.requirements ul span {
  color: #00bc83;
  font-family: "Noto Sans";
}