* {
    margin: 0;
    padding: 0;
  }

  .first_view_container {
    margin: 2rem;
  }
  
  .first_view {
    display: flex;
    flex-direction: column-reverse;
  }
  
  .fv_heading {
    font-size: 2.4rem;
    font-weight: 700;
    color: #404040;
    font-family: "Noto Sans", sans-serif;
    margin-bottom: 0.5rem;
  }
  
  .fv_heading span,
  .orange {
    color:#F0DB4F;
  }
  .officehrs {
    margin-top: 1rem;
    font-family: "Noto Sans", sans-serif;
    font-weight: 500;
    color: #404040;
  }
  
  .officehrs span {
    color: #f6842c;
  }
  .fv_content {
    font-family: "Poppins", sans-serif;
    font-size: 1rem;
  }
  
  .fv_img {
    width: 25rem;
  }

  .atag {
    display: initial;
    vertical-align: initial;
    white-space: initial;
    max-width: 100%;
  }
  
  @media only screen and (min-width: 1000px) {
    .first_view_container {
      margin: 0 6rem;
    }
  
    .fv_texts {
      width: 45vw;
    }
  
    .fv_heading {
      font-size: 5rem;
    }
  
    .fv_content,
    .aquote {
      width: 80%;
    }
  
    .fv_content {
      font-size: 1.1rem;
    }
  
    .fv_texts {
      padding-right: 3vw;
      color: #404040;
    }
  
    .fv_image {
      display: block;
    }
  
    .fv_img {
      width: 35rem;
    }
  
    .first_view {
      display: flex;
      justify-content: space-evenly;
      flex-direction: row;
      align-items: center;
    }
  }
  
  .steps_ulist {
    list-style: disc;
    margin-left: 1.5rem;
    max-width: 50rem;
  }
  
  .steps_ulist img {
    margin: 1rem 0 0;
    border-radius: 0.3rem;
  }
  
  .first_view_container {
    margin: 2rem;
  }
  
  .ss_title {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;
  }
  
  .callout {
    margin: 2rem auto 0;
    border: 1px solid #87BBFE;
    padding: 2rem;
    border-radius: 0.2rem;
    max-width: 50rem;
  }
  
  .register {
    background-color:#F0DB4F;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    color: #404040;
    margin-top: 1rem;
  }
  
  @media only screen and (min-width: 1000px) {
    .first_view_container {
      display: block;
      margin: 6rem;
    }
  }
  
  @media only screen and (max-width: 1000px) {
    .fv_img {
      display: block;
      margin: auto;
    }
  
    .fv_heading {
      text-align: center;
      font-size: 3.5rem;
    }
  
    .fv_content {
      text-align: center;
    }
  
    .register {
      display: block;
      margin: auto;
      margin-top: 1rem;
    }
  }
  
  /* Second Section Sytles */
  
  .second_view_container {
    margin: 2rem;
    margin-top: 4rem;
  }
  
  .screenshots {
    max-width: 50rem;
    margin: 5rem auto 0;
  }
  
  .screenshots img {
    margin-bottom: 3rem;
    border-radius: 0.5rem;
  }
  
  .steps {
    margin-top: 1rem;
  }
  
  .steps_ulist_sub li {
    margin-top: 0.5rem;
  }
  
  b {
    color: #404040;
  }
  
  .steps_ulist li {
    margin-top: 1rem;
  }
  
  .sample_message a {
    font-size: 0.9rem;
  }
  
  .sample_container {
    display: block;
    margin: auto;
    margin-top: 1rem;
    background: #f5f5f5;
    padding: 2rem;
    border-radius: 0.2rem;
    max-width: 50rem;
  }
  