.problem_card_container {
  font-family: Poppins, sans-serif;
  /* border: 2px solid #dcdcdc; */
  padding: 25px 25px;
  border-radius: 10px;
  transition: all 0.3s ease-out;
  background: rgba(255, 255, 255, 0.35);
  box-shadow: 0 1px 12px 0 rgb(193 195 211 / 37%);
  border: 1px solid rgba(255, 255, 255, 0.18);
  border-radius: 10px;
  width: 20rem;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.problem_card_container:hover {
  transform: translateY(-0.5px);
}

.label {
  /* color: var(--color-primary); */
  color: black;
  font-weight: 600;
  font-size: 1rem;
  margin-top: 1rem;
}

.label span,
.readhide {
  color: #f78c40;
  font-size: 1rem;
  cursor: pointer;
}

.contribute {
  font-size: 0.9rem;
  padding: 0.25rem 2rem;
  margin-top: 0.75rem;
  border: none;
  border-radius: 3px;
  color: white;
  background-color: #f78c40;
}
