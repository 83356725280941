* {
  margin: 0;
  padding: 0;
}

.main_container {
  min-height: 60vh;
}

.first_view_container {
  margin: 2rem;
}

.first_view {
  display: flex;
  flex-direction: column-reverse;
}

.fv_heading {
  font-size: 2.4rem;
  font-weight: 700;
  color: #404040;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: 0.5rem;
}

.fv_heading span,
.orange {
  color: #f78c40;
}

.fv_content {
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

.fv_img {
  height: 20rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    margin: 0 6rem;
  }

  .fv_texts {
    width: 45vw;
  }

  .fv_heading {
    font-size: 5rem;
  }

  .fv_content,
  .aquote {
    width: 80%;
  }

  .fv_content {
    font-size: 1rem;
  }

  .fv_texts {
    padding-right: 3vw;
    color: #404040;
  }

  .fv_image {
    display: block;
  }

  .fv_img {
    height: 35rem;
  }

  .first_view {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    align-items: center;
  }
}

/* Second Section Styles */

.second_view_container {
  margin: 2rem;
}

.sv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.75rem;
  color: #f78c40;
}

.sv_content {
  margin-top: 0.25rem;
  font-size: 0.9rem;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    margin: 1rem 6rem 2rem;
  }

  .sv_heading {
    font-size: 2.25rem;
    line-height: 4rem;
    width: 60vw;
  }

  .sv_content {
    width: 40%;
    margin-top: 0;
    font-size: 1.05rem;
  }
}

.problem_list_container {
  display: grid;
  margin-top: 2rem;
}

@media only screen and (min-width: 768px) {
  .problem_list_container {
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
  }
}

@media only screen and (min-width: 1300px) {
  .problem_list_container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
