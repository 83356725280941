@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --color-primary: #f78c40;
}

html {
  scroll-behavior: smooth;
}

.text-lg a:hover {
  color: black;
}
