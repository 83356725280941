.dashes{
  border-bottom: 3px solid rgba(0, 0, 0, 0);
  
}
.dashes:hover{
  border-bottom: 3px solid black;
}
.special {
  font-family: Montserrat, Arial;
  line-height: 1.3em;
  text-decoration: none;
  position: relative;
  z-index: 500;
}

a{
  max-width: 100%;
}

.special::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.special::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(158, 84, 0, 0.795);
  border-radius: 10px;
  padding: 0 5px;
  max-height: max-content;
}

/* Handle */
.special::-webkit-scrollbar-thumb {
  background: hsla(0, 0%, 100%, 0.247);
  border-radius: 10px;
}

.notifications {
  position: absolute;
  width: 300px;
  background-color: white;
  color: rgb(255, 255, 255);
  margin-top: 16px;
  font-size: 0.75rem;
  line-height: 1rem;
  border-radius: 0.75rem;
  box-shadow:1px 1px 5px 2px rgba(0, 0, 0, 0.25 );
  border: 1px solid rgba(0 0 0 /0.07);
}

.mobile-nav-bar {
  display: flex;
  padding: 1.25rem;
  margin-top: .25rem;
  background-color: white;
  justify-content: space-between;
}
@media screen and (max-width: 1024px) {
  .mobile-nav-bar {
    z-index: 100;
  }
}
  
@media screen and (max-width: 1200px) {
  .change-text{
    font-size: 14px;
  }
}
@media screen and (max-width: 1100px) {
  .change-text{
    font-size: 12px;
  }
}
.setIndex{
  z-index: 90;

}
.searchBar{
  border-radius: 10px;
  background-color: rgba(0, 0, 0, 0.056);
  margin: 25px;
  border: none;
}