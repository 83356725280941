* {
  margin: 0;
  padding: 0;
}

.first_view_container {
  margin: 2rem;
}

.first_view {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: flex-start;
}

.fv_img {
  display: block;
  width: 20rem;
}

.fv_heading {
  font-size: 2.8rem;
  font-weight: 700;
  color: #404040;
  font-family: "Noto Sans", sans-serif;
  margin-bottom: 0.5rem;
}

.fv_heading span {
  color: #f78c40;
}

.fv_content {
  margin-top: 0.5rem;
  font-family: "Poppins", sans-serif;
  font-size: 1rem;
}

@media only screen and (min-width: 1000px) {
  .first_view_container {
    margin: 2rem 6rem;
  }

  .first_view {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .fv_texts {
    max-width: 40rem;
    margin: 3rem 0;
  }

  .fv_heading {
    font-size: 4.25rem;
  }

  .fv_content {
    width: 80%;
    font-size: 1.1rem;
  }

  .fv_img {
    display: block;
    width: 30rem;
  }
}

/* Second Section Styles */

.second_view_container {
  margin: 2rem;
  margin-top: 4rem;
}

.sv_heading {
  font-family: "Noto Sans", sans-serif;
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.75rem;
  color: #f78c40;
}

.sv_content {
  margin-top: 0.25rem;
  font-family: "Poppins";
  max-width: 30rem;
}

.mentors {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 1rem;
  column-gap: 1rem;
}

@media only screen and (min-width: 1000px) {
  .second_view_container {
    margin: 2rem 6rem;
  }

  .sv_heading {
    font-size: 2.25rem;
    line-height: 4rem;
    width: 60vw;
  }

  .sv_content {
    margin-top: 0;
    font-size: 1.05rem;
  }
}
